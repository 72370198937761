import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import GUI from 'lil-gui'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { gsap } from "gsap";

/**
 * About module
 */
let open = false
let abtOpen = document.querySelector(".aboutBtnOpen")
let abtClose = document.querySelector(".aboutBtnClose")
let about = document.querySelector(".about-container")
let layer = document.querySelector(".layer")
let neve = document.querySelector(".neveBtn")
abtOpen.addEventListener("click", ()=> {
    if(!open) {
        layer.style.display = "block"
        about.style.pointerEvents = "all"
        gsap.to(layer, { opacity: 0.7, duration: 0.25 });
        gsap.to(about, { opacity: 1, duration: 0.25 });
        gsap.to(neve, { opacity: 1, duration: 0.25, delay: 0.15});
        gsap.from(about, { width: "45vw", height: "45vh", duration: 0.25, ease: "power1.out", });
        gsap.from('.about-btn', { opacity: 0, duration: 0.25, delay: 0.15 });
        gsap.from('.about-content', { opacity: 0, duration: 0.25, delay: 0.25, onComplete: () => {
            open = true
        } });
    }
})

const closeAbout = () => {
    if(open) {
        gsap.to(layer, { opacity: 0, duration: 0.2 });
        gsap.to(about, { opacity: 0, duration: 0.2 });
        gsap.to(neve, { opacity: 0, duration: 0.2, onComplete:()=> {
            open = false
        }});
        layer.style.display = "none"
        about.style.pointerEvents = "none" 
    }
}
abtClose.addEventListener("click", closeAbout)
layer.addEventListener("click", closeAbout)

/**
 * Marquee
 */
let dur = 300
let text1 = document.querySelectorAll(".line.top .text")
let text2 = document.querySelector(".line.bottom .text")

//top line transformation
let trans = (text1[0].clientWidth * 2) - window.innerWidth
text1[0].style.transform ='translateX(-'+trans+'px)'
text1[1].style.transform ='translateX(-'+trans+'px)'

const startAnimations = () => {
gsap.to(".text", {
    duration: 0.4,
    opacity: 1,
    ease: "none",
    delay: 0.5
});
gsap.to(".webgl", {
    duration: 0.4,
    opacity: 1,
    ease: "none",
    delay: 0.2
});

//top line
gsap.to(".line.top .text", {
  duration: dur,
  x: "+="+text1[0].clientWidth, 
  modifiers: {
    // x: gsap.utils.unitize(x => parseFloat(x) % text1[0].clientWidth) 
  },
  repeat: -1
});

//bottom line 
gsap.to(".line.bottom .text", {
  duration: dur,
  x: "-="+text2.clientWidth, 
  modifiers: {
    x: gsap.utils.unitize(x => parseFloat(x) % text2.clientWidth) 
  },
  repeat: -1
});

}


/**
 * Base
 */
// Debug
//const gui = new GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

scene.background = null;
/**
 * Textures
 */

const textureLoader = new THREE.TextureLoader();
let textureLoaded = []
var matcapTexture = textureLoader.load("https://res.cloudinary.com/neve/image/upload/q_auto,f_auto/esc%20website/3-octane-matcap.png");
var baseTexture = textureLoader.load('/models/ao depth_3.png'); 
var matList=[
    //cloudinary
    // "2-verde-matcap.png",
    "https://res.cloudinary.com/neve/image/upload/q_auto,f_auto/esc%20website/2-verde-matcap.png",
    "https://res.cloudinary.com/neve/image/upload/q_auto,f_auto/esc%20website/4-Viola-matcap.png",
    "https://res.cloudinary.com/neve/image/upload/q_auto,f_auto/esc%20website/5-Metal-matcap.png",
    "https://res.cloudinary.com/neve/image/upload/q_auto,f_auto/esc%20website/6-Nero-matcap.png",
    "https://res.cloudinary.com/neve/image/upload/q_auto,f_auto/esc%20website/1-bianco-alternativa-matcap.png",
    "https://res.cloudinary.com/neve/image/upload/q_auto,f_auto/esc%20website/8-Rosso-matcap.png",
    "https://res.cloudinary.com/neve/image/upload/q_auto,f_auto/esc%20website/6A3C15_EFC898_D59D59_B38346.jpg",
    "https://res.cloudinary.com/neve/image/upload/q_auto,f_auto/esc%20website/3-octane-matcap.png",  
]

//preload textures
matList.forEach(texture => {
    let text = textureLoader.load(texture)
    textureLoaded.push(text)
});

/**
 * Models
 */
const dracoLoader = new DRACOLoader()
dracoLoader.setDecoderPath('/draco/')
var modd;
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoLoader)

gltfLoader.load(
    '/models/escnew.glb',
    (gltf) =>
    {
        let model = gltf.scene
        modd=gltf.scene;
        baseTexture.flipY = false;
        model.rotation.y=Math.PI/4;
        console.log(window.innerWidth)
        if(window.innerWidth<768)
        model.scale.set(0.15, 0.15, 0.15) //togliere se è scalato normale
         else
        model.scale.set(0.3, 0.3, 0.3) //togliere se è scalato normale
        model.traverse((child) => {
          if (child instanceof THREE.Mesh) {
            child.material = new THREE.MeshMatcapMaterial({
              // map: baseTexture,
               
                matcap: matcapTexture
            });
          }
        });

        //fare cosi se vuoi dare diversi materiali a diverse mesh
        
        // if (mesh instanceof THREE.Mesh) {
        //   console.log("mats: ", mesh.material.name);
        //   switch (mesh.material.name) {
        //     case "nome mat":
        //       mesh.material = new THREE.MeshBasicMaterial({
        //         map: pipetteInsideBaseColor,
        //         transparent: true,
        //         side: THREE.DoubleSide,
        //         name: "nome mat",
        //       });
        //       break;
        //   }
        // }
        scene.add(model)
        startAnimations()
    }
)
var index=0;
document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
        // matcapTexture = textureLoader.load('/models/' + matList[index]);
        matcapTexture = textureLoaded[index]; //cloudinary
        index = (index + 1) % matList.length;

        scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = new THREE.MeshMatcapMaterial({
                  //  map: baseTexture,
                    matcap: matcapTexture
                });
            }
        });
    }
});
var tapLength;
var tapTimeout, lastTapTime;
document.addEventListener('touchstart', (event) => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTapTime;
    clearTimeout(tapTimeout);
    if (tapLength < 500 && tapLength > 0) {
        matcapTexture = textureLoaded[index]; //cloudinary
        index = (index + 1) % matList.length;
        scene.traverse((child) => {
            if (child instanceof THREE.Mesh) {
                child.material = new THREE.MeshMatcapMaterial({
                  //  map: baseTexture,
                    matcap: matcapTexture
                });
            }
        });
   
    } else {
        tapTimeout = setTimeout(() => {
            clearTimeout(tapTimeout);
        }, 500);
    }
    lastTapTime = currentTime;
});


/**
 * Lights
 */
const ambientLight = new THREE.AmbientLight(0xffffff, 2.4)
scene.add(ambientLight)

const directionalLight = new THREE.DirectionalLight(0xffffff, 1.8)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 15
directionalLight.shadow.camera.left = - 7
directionalLight.shadow.camera.top = 7
directionalLight.shadow.camera.right = 7
directionalLight.shadow.camera.bottom = - 7
directionalLight.position.set(- 5, 5, 0)
scene.add(directionalLight)

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.set(2, 0, 2)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.target.set(0, 0, 0)
controls.enableDamping = true
controls.enablePan = true
// Controls
controls.minDistance = 3.5; // minimum zoom distance
controls.maxDistance = 10; // maximum zoom distance
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,

    alpha: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
const clock = new THREE.Clock()
let previousTime = 0

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()
    const deltaTime = elapsedTime - previousTime
    previousTime = elapsedTime

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)
    
    // Call tick again on the next frame
    window.requestAnimationFrame(tick)

    if(elapsedTime<0)return;
    scene.traverse((child) => {
        if (child instanceof THREE.Mesh) {
            child.rotation.z += 0.005; // Rotate child on y axis
        }
    });
    
}

tick()